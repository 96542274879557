import Cookies from 'js-cookie';

// ALL DATES MUST BE IN THIS FORMAT
// yyyy--mm-dd

// API
// [urlPrefix]/umbraco/api/signupform/submit

//JSON FORMAT To SEND TO THE API
//{
//    "Email": "someone@isobar.com",
//    "FirstName": "John",
//    "LastName": "Doe",
//    "Salutation": "Mr.",
//    "PostalCode": "E1 7AE",
//    "Country": "GB",
//    "BirthDate": "1980-03-29",
//    "Language": "EN",
//    "ConsentType": "Merlin Global",
//    "Campaign": "NewTown",
//    "Attraction": "ELO",
//    "Source": "Website",
//    "SourceDetail": "www.londoneye.com/visitor-information/",
//    "TimeStamp": "2019-10-18T18:42:33.000Z"
//}
window.addEventListener("DOMContentLoaded", function (e) {

    const siteConfig = {};
    Object.assign(siteConfig, window.merlinConfig);
    const newsletterEndpoint = `${siteConfig.urlPrefix}/umbraco/api/signupform/submit`;
    const modalNewsletterEndpoint = `${siteConfig.urlPrefix}/umbraco/api/signupform/submitfrommodal`;
    var footerSignupForm = $("#footerSignupForm");
    var newsletterSignupForm = $("#newsletterSignupForm");
    var modalNewsletterSignupForm = $("#modalNewsletterSignupForm");
    var newsletterSignupThankyouText = $("#newsletterSignupThankyouText");
    var newsletterError = $("#newsletterError");
    var phoneNumberRegex = /^[0-9\-]{7,20}$/;
    var validator = null;
    var modalValidator = null;

    var newsletterSignupFormContent = modalNewsletterSignupForm.find(".form-content");
    var newsletterSignupFormResponseContent = modalNewsletterSignupForm.find(".form-response-content");

    var dateInput = document.querySelector("#formDate");
    var month = document.querySelector("#DateOfBirthMonth");
    var day = document.querySelector("#DateOfBirthDay");
    var year = document.querySelector("#DateOfBirthYear");
    var phoneNumber = document.querySelector("#PhoneNumber");

    let options = {};

    function addLeadingZero(num) {
        if (num < 10) {
            return "0" + num;
        }
        return num;
    }

    function isValidDate(year, month, day) {
        var d = new Date(year, month, day);
        if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
            return true;
        }
        return false;
    }

    function validateDate() {

        dateInput.value = "";

        if (parseInt(year.options[year.selectedIndex].value) &&
            parseInt(month.options[month.selectedIndex].value) &&
            parseInt(day.options[day.selectedIndex].value)) {
            dateInput.value = year.options[year.selectedIndex].value + "-" + addLeadingZero(month.options[month.selectedIndex].value) + "-" + addLeadingZero(day.options[day.selectedIndex].value);
        }

        validator.element("#formDate");

    }

    if (month && day && year) {

        month.addEventListener("change", function () {
            validateDate();
        });

        day.addEventListener("change", function () {
            validateDate();
        });

        year.addEventListener("change", function () {
            validateDate();
        });
    }

    var customValidations = (function () {
        function isDate(input) {
            var y = year.options[year.selectedIndex].value;
            var m = month.options[month.selectedIndex].value;
            m = parseInt(m, 10) - 1; //Months are zero indexed
            var d = day.options[day.selectedIndex].value;

            return isValidDate(y, m, d);
        }

        return {
            isValidDate: function (value) {
                return isDate(value);
            },
            isValidPhoneNumber: function (value) {
                return phoneNumberRegex.test(value);                
            }
        }


    })();

    $.validator.addMethod("isvaliddate", function (value, element) {
        return this.optional(element) || customValidations.isValidDate(value);
    });

    $.validator.addMethod("isvalidphonenumber", function (value, element) {
        return this.optional(element) || customValidations.isValidPhoneNumber(value);
    });

    $.fn.serializeObject = function () {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name]) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    if (footerSignupForm.length > 0) {
        footerSignupForm.validate();
    }

    if (newsletterSignupForm.length > 0) {

        options = {
            errorPlacement: function (error, element) {
                if (element.attr("name") === "Salutation") {
                    error.appendTo(element.parents("div"));
                } else if (element.attr("name") === "Permission") {
                    error.appendTo(element.parents("p"));
                } else {
                    error.insertAfter(element);
                }
            }
        }

        validator = newsletterSignupForm.validate(options);

        newsletterSignupForm.submit(function (e) {

            e.preventDefault();

            // check if the input is valid
            if (!newsletterSignupForm.valid()) {
                return;
            } else {
                const redirectURl = newsletterSignupForm.data("redirecturl");
                const formData = newsletterSignupForm.serializeObject();
                const formDataAsJson = JSON.stringify(formData);
                var authorizationToken = newsletterSignupForm.find("input[name='__RequestVerificationToken']").val();

                var jqxhr = $.ajax({
                    type: 'POST',
                    beforeSend: function (request) {
                        request.setRequestHeader("X-XSRF-Token", authorizationToken);
                    },
                    url: newsletterEndpoint,
                    data: formDataAsJson,
                    contentType: "application/json; charset=utf-8"                
                }).done(function (response) {
                    if (response) {
                        switch (response) {
                            case "recaptchaerror":
                                //console.log('recaptchaerror');
                                document.querySelector('#recaptchaErrorText').style.display = 'block';

                                try {
                                    document.querySelector('#recaptchaErrorText').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
                                } catch (e) {
                                }
                                break;
                            case "error":
                                //console.log('error');
                                newsletterSignupForm.hide();
                                newsletterError.show();

                                try {
                                    newsletterError.get(0).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
                                } catch(e) {
                                    window.scrollTo(0, 0);
                                }
                                
                                break;
                            default:
                                //console.log("success");
                                window.dataLayer.push({ "event": "newsletter-signup" });

                                if (redirectURl.trim() !== '') {
                                    window.location.href = redirectURl;
                                } else {
                                    newsletterSignupForm.hide();
                                    newsletterSignupThankyouText.addClass("newsletter-signup-receipt"); //GTM
                                    newsletterSignupThankyouText.show();
                                    
                                    try {
                                        newsletterSignupThankyouText.get(0).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
                                    } catch (e) {
                                        window.scrollTo(0, 0);
                                    }
                                }
                        }
                    }
                }).fail(function (response) {
                    //console.log("fail: ", response);
                    newsletterSignupForm.hide();
                    newsletterError.show();
                    
                    try {
                        newsletterError.get(0).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
                    } catch (e) {
                        window.scrollTo(0, 0);
                    }
                }).always(function () {
                    //console.log("complete");
                });
            }
        });

    }

    if (modalNewsletterSignupForm.length > 0) {

        options = {
            errorPlacement: function (error, element) {
                if (element.attr("name") === "Permission") {
                    error.appendTo(element.parents("p"));
                } else {
                    error.insertAfter(element);
                }
            }
        }

        modalNewsletterSignupForm.validate(options);

        modalNewsletterSignupForm.submit(function (e) {

            e.preventDefault();

            // check if the input is valid
            if (!modalNewsletterSignupForm.valid()) {
                return;
            }

            const takeover = document.querySelector('.takeover');
            const modalGuid = takeover.dataset.modalGuid;
            const formData = modalNewsletterSignupForm.serializeObject();
            const formDataAsJson = JSON.stringify(formData);
            const submitbutton = modalNewsletterSignupForm.find("button[type='submit']");
            submitbutton.prop("disabled", true);
            submitbutton.find(".spinner").show();

            console.log("modalGuid", modalGuid);

            var authorizationToken = modalNewsletterSignupForm.find("input[name='__RequestVerificationToken']").val();
            var jqxhr = $.ajax({
                type: 'POST',
                beforeSend: function (request) {
                    request.setRequestHeader("X-XSRF-Token", authorizationToken);
                },
                url: modalNewsletterEndpoint,
                data: formDataAsJson,
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            })
                .done(function (response) {


                    if (response) {
                        console.log("success");
                        newsletterSignupFormContent.hide();
                        newsletterSignupFormResponseContent.find("[data-success-msg]").removeAttr("hidden");

                        var cookie = JSON.parse(Cookies.get('merlin-modals'));

                        if (cookie.Modals && cookie.Modals.length > 0) {

                            const modalData = cookie.Modals.find(modal => modal.ModalId === modalGuid);
                            const modalIndex = cookie.Modals.findIndex((modal => modal.ModalId === modalGuid));
                            var modalDate = new Date(modalData.ShowAfter);
                            var showAfterDate = new Date();
                            showAfterDate.setDate(modalDate.getDate() + 365);

                            cookie.Modals[modalIndex].ShowAfter = showAfterDate.toISOString();

                            document.cookie = 'merlin-modals=' + JSON.stringify(cookie) + ';path=/; expires=' + showAfterDate.toUTCString();
                        }
                    } else {
                        newsletterSignupFormContent.hide();
                        newsletterSignupFormResponseContent.find("[data-error-msg]").removeAttr("hidden");
                    }

                    try {
                        $("[data-legaltext]").remove();
                    } catch (e) {
                    }

                })
                .fail(function () {
                    console.log("error");
                    newsletterSignupFormContent.hide();
                    newsletterSignupFormResponseContent.find("[data-error-msg]").removeAttr("hidden");
                })
                .always(function () {
                    console.log("complete");
                });
        });

    }
});